/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	// Registering GSAP plugin

	gsap.registerPlugin(ScrollTrigger);

	// Global Variables

	var pageY = 0,
		factor = 0,
		headerHeight = $('#masthead').outerHeight(true) + $('.site-logo').outerHeight(),
		menuButton = $('.menu-button'),
		progress = $('.progress-bar'),
		indicator = progress.find('.indicator');

	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - headerHeight
				}, 1000);
			}
		});
	}

	// Code to run when the document is ready

	$( document ).ready(function() {

		allClick();
		smoothScroll();

		// Intro Animations

		$('#page').addClass( 'init' );

		setTimeout(function() {
			$('.logo-name').removeClass( 'hidden' );
			$('.logo-payoff').removeClass( 'hidden' );
		}, 1500);

		// Remove videos autoplay when the user has requested
		// the operating system to minimize the amount of motion it uses

		var video = $('video');
		if ( window.matchMedia('(prefers-reduced-motion)').matches ) {
			video.removeAttribute('autoplay');
			video.pause();
		}

		// Inhibit click on primary menu items

		$('#primary-menu').find('a[href="#"]').each(function() {
			$(this).on('click', function(e) {
				e.preventDefault();

				$('#primary-menu .menu-item > a').not(this).next('.sub-menu').removeClass('open');

				if ( $(this).next('.sub-menu').hasClass('open') ) {
					$(this).next('.sub-menu').removeClass('open');
				} else {
					$(this).next('.sub-menu').addClass('open');
				}
			});
		});

		// Manage images menu

		$('#primary-menu > li').on('mouseenter', function() {
			var item = $(this);

			$('.menu-image').removeClass('visible');
			$('.menu-image:eq(' + (item.index() + 1) + ')').addClass('visible');
		});

		// Items filtering

		$('.item-filters .filter').on('click', function(event) {
			event.preventDefault();
			$('.item-filters .filter').removeClass('active');
			$(this).addClass('active');

			var mix = $(this).data('filter');
			var items = $('[data-mix]');

			if ( mix != "all" ) {
				items.hide();
				$('[data-mix*="' + mix + '"]').fadeIn(500);
			} else {
				items.fadeIn(500);
			}
		});

	});

	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {

		/* GSAP Animations - Begin */

		// Menu
		var menu = gsap.timeline({
			paused: true
		});

		menu.to(
			'#menu-offcanvas',
			{
				duration: 0.5,
				scaleX: 1,
				ease: 'Power2.inOut'
			}
		).to(
			'.menu-top',
			{
				duration: 0.25,
				opacity: 1,
				ease: 'Power2.inOut'
			}
		).to(
			'.menu-logo',
			{
				duration: 0.25,
				opacity: 1,
				ease: 'Power2.inOut'
			}
		).to(
			'.images-navigation',
			{
				duration: 0.25,
				opacity: 1,
				ease: 'Power2.inOut'
			}
		).to(
			'.menu-item',
			{
				duration: 0.5,
				opacity: 1,
				ease: 'Power2.inOut',
				stagger: 0.1
			},
			'-=0.2'
		).to(
			'.menu-bottom',
			{
				opacity: 1,
				ease: 'Power2.inOut'
			}
		);

		if ( $(window).width() >= 1024 ) {
			// Reasons (pinned)
			var reasons = gsap.utils.toArray('.reason');
			reasons.pop();

			reasons.forEach((reason) => {
				var reasonHeight = reason.offsetHeight,
					windowHeight = window.innerHeight,
					difference = reasonHeight - windowHeight;
				
				var fakeScrollRatio = difference > 0 ? (difference / (difference + windowHeight)) : 0;

				if (fakeScrollRatio) {
					reason.style.marginBottom = reasonHeight * fakeScrollRatio + "px";
				}

				let tl = gsap.timeline({
					scrollTrigger:{
						trigger: reason,
						start: 'bottom bottom',
						end: () => fakeScrollRatio ? `+=${reasonHeight}` : 'bottom top',
						pinSpacing: false,
						pin: true,
						scrub: true
					}
				});

				if (fakeScrollRatio) {
					tl.to(
						reason,
						{
							y: -difference,
							duration: 1 / (1 - fakeScrollRatio) - 1,
							ease: 'none'
						}
					);
				}

				tl.fromTo(
					reason,
					{
						scale: 1,
						opacity: 1
					},
					{
						scale: 0.5,
						opacity: 0.5,
						duration: 0.9
					}
				).to(
					reason,
					{
						opacity: 0,
						duration: 0.1
					}
				);
			});
		}

		if ( $(window).width() > 767 ) {
			// Titles (sliding up)
			var titles = gsap.utils.toArray('.slide');

			titles.forEach((title) => {
				var delay = title.getAttribute('data-delay'),
					scroll = title.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				title.parentNode.style.overflow = 'hidden';

				gsap.from(
					title,
					{
						delay: delay,
						duration: 1.5,
						yPercent: 120,
						scrollTrigger: {
							trigger: title,
							start: start
						}
					}
				);
			});

			// Texts (fading up)
			var texts = gsap.utils.toArray('.fade');

			texts.forEach((text) => {
				var delay = text.getAttribute('data-delay'),
					scroll = text.getAttribute('data-scroll'),
					start = 'top center';

				if ( scroll == 'no' ) start = 'top bottom';

				gsap.from(
					text,
					{
						delay: delay,
						duration: 1,
						autoAlpha: 0,
						y: 50,
						scrollTrigger: {
							trigger: text,
							start: start
						}
					}
				);
			});

			// Images reveal
			var containers = gsap.utils.toArray('.reveal');

			if ( containers.length > 0 ) {
				containers.forEach((container) => {
					var image = container.querySelector('img'),
						tl = gsap.timeline({
							scrollTrigger: {
								trigger: container,
								start: 'top center'
							}
						});
					
					tl.set(
						container,
						{
							autoAlpha: 1
						}
					);

					if ( container.classList.contains('right-side') ) {
						tl.from(
							container,
							2,
							{
								xPercent: 100,
								ease: 'Power2.easeOut'
							}
						);

						tl.from(
							image,
							2,
							{
								xPercent: -100,
								scale: 1.3,
								delay: -2,
								ease: 'Power2.easeOut'
							}
						);
					} else {
						tl.from(
							container,
							2,
							{
								xPercent: -100,
								ease: 'Power2.easeOut'
							}
						);

						tl.from(
							image,
							2,
							{
								xPercent: 100,
								scale: 1.3,
								delay: -2,
								ease: 'Power2.easeOut'
							}
						);
					}
				});
			}

			// Single letter animation
			$('.letter-animation').each(function(index) {
				var triggerElement = $(this);
				var targetElement = $(this).find('span');
			
				var tl = gsap.timeline({
					scrollTrigger: {
						trigger: triggerElement,
						start: 'top bottom',
						end: 'bottom top'
					}
				});

				tl.from(targetElement, {
					duration: 1.5,
					y: '60%',
					opacity: 0,
					rotationX: -90,
					stagger: {
						amount: 0.7,
						from: '0'
					}
				});
			});

			// Lines animation
			$('.line-animation').each(function(index) {
				var triggerElement = $(this);
				var targetElement = $(this).find('span');
			
				var tl = gsap.timeline({
					scrollTrigger: {
						trigger: triggerElement,
						start: 'top bottom',
						end: 'bottom top'
					}
				});

				tl.from(targetElement, {
					duration: 3,
					y: '60%',
					opacity: 0,
					stagger: {
						amount: 0.7,
						from: '0'
					}
				});
			});
		}

		/* GSAP Animations - End */

		// Hide/show menu off canvas

		menuButton.on('click', function(e) {
			e.preventDefault();
			
			$(this).toggleClass( 'collapsed' );

			if ( ! $(this).hasClass('collapsed') ) {
				$('#primary-menu .menu-item > a').next('.sub-menu').removeClass('open');
				menu.play();
			} else {
				menu.reverse();
			}

			$('body').toggleClass( 'open-menu' );
		});

		// Equal Heights
		if ( $(window).width() >= 1024 ) {
			$('.equalHeight').equalHeights();
		}

	});

	// Code to run when the browser window has been resized

	$( window ).on( 'resize', function() {

		

	});

	// Code to run when the browser window scrolls

	$( window ).on( 'scroll', function() {

		if ( $(window).width() >= 1024 ) {
			pageY = window.scrollY;

			// Progress bar
			factor = ( 1 / ( $(document).height() - $(window).height() ) ) * pageY;
			indicator.css( 'transform', 'scale(1,' + factor + ')' );
		}

	});

})(jQuery);

// Magic mouse

if ( document.documentElement.clientWidth > 1199 ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

/* Swiper - Begin */

// Reasons slideshow
const reasonsSlider = new Swiper('#reasons .reasons-slideshow', {
	loop: true,
	slidesPerView: 1,
	speed: 1000,
	navigation: {
		nextEl: '#reasons .reasons-nav-next',
		prevEl: '#reasons .reasons-nav-prev',
	},
});

// Rooms slideshow
const roomsContents = new Swiper('.room-gallery .rooms-slideshow', {
	loop: true,
	effect: 'coverflow',
	grabCursor: true,
	centeredSlides: true,
	slidesPerView: 'auto',
	coverflowEffect: {
		rotate: 0,
		stretch: 0,
		depth: 100,
		modifier: 3.5,
	},
	navigation: {
		nextEl: '.rooms-nav-next',
		prevEl: '.rooms-nav-prev',
	},
});

// Reviews slideshow
const reviewsSlider = new Swiper('#reviews .reviews-slideshow', {
	loop: true,
	slidesPerView: 'auto',
	centeredSlides: true,
	allowTouchMove: false,
	speed: 25000,
	autoplay: {
		delay: 5,
		disableOnInteraction: false,
	},
});

/* Swiper - End */
